import { useEffect } from 'react';

// Component
import CenterBatchSelect from '../common/select/CenterBatchSelect';

// Mantine
import { useDisclosure } from '@mantine/hooks';
import { LoadingOverlay } from '@mantine/core';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectedBatchData,
  setSelectedBatch,
} from '../../features/batch/batchSlice';
import { setCenterRoomBatchData } from '../../features/master/masterSheetSlice';
import type { RootState } from '../../app/store';

// React Toastify
import { toast } from 'react-toastify';

// Constants and Utils
import styles from './Batch.module.css';
import { serverUrl } from '../../utils/serverUrl';
import { messages } from '../../constants';

type Props = {
  close: () => void;
  room?: string | null;
};

const Batch = ({ close, room }: Props) => {
  // Overlay for the save button
  const [isOverlayVisible, { open: openOverlay, close: closeOverlay }] =
    useDisclosure(false);
  const dispatch = useDispatch();

  // To get the info about the selected batch
  const { selectedBatch, batchSelectData, selectedBatchData, batchData } =
    useSelector((state: RootState) => state.batchSlice);

  // console.log('selectedBatch: ', selectedBatch);

  // To get the filtered data of the selected center
  const { data: filteredCenterRoomsBatches } = useSelector(
    (state: RootState) => state.masterSheetSlice
  );

  const { selectedCenter } = useSelector(
    (state: RootState) => state.centerSlice
  );

  const batchName = selectedBatchData && selectedBatchData[0]?.batchName;
  const batchId = selectedBatchData && selectedBatchData[0].batchId;

  // console.log('Selected Batch: ', selectedBatch);
  // console.log('All Batch data: ', batchData);
  // console.log('Selected Batch Data: ', selectedBatchData);

  // Logic to filter out the selected batch
  useEffect(() => {
    // Filtering the selected batch from all the batch data
    const selectedBatchData =
      batchData?.filter((batches) => batches.batchName === selectedBatch) ??
      null;

    // Setting the data of the selected batch
    selectedBatch
      ? dispatch(setSelectedBatchData(selectedBatchData))
      : dispatch(setSelectedBatchData(null));
  }, [dispatch, selectedBatch, batchData]);

  // To add the room --> batch mapping in the sheets
  const handleSave = async () => {
    const masterSheetRowData = {
      centerName: selectedCenter,
      roomNumber: room,
      batchName: selectedBatch,
      batchId: selectedBatchData ? selectedBatchData[0].batchId : '',
    };

    openOverlay();

    // To get the name and email from the local storage
    const { name, email } = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user') as string)
      : { name: '', email: '' };

    // Request to add the row in the sheets
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('refresh_token') as string,
        },
        body: JSON.stringify(masterSheetRowData),
      };

      const response = await fetch(
        `${serverUrl}/sheets/add?name=${name}&email=${email}`,
        requestOptions
      );
      const data = await response.json();

      console.log('Data: ', data);
      toast.success(messages.success.addBatch);
    } catch (error) {
      toast.error(messages.error.addBatch);
      console.log('Error: ', error);
    }

    close();

    // Resetting the selected batch and selected batch data
    dispatch(setSelectedBatchData(null));
    dispatch(setSelectedBatch(null));

    // Request to fetch the updated data from the sheets
    const requestUrl = `${serverUrl}/sheets/all?selectedCenter=${selectedCenter}`;

    try {
      const response = await fetch(requestUrl);
      const data = await response.json();

      // Setting the updated data of the selected center
      dispatch(setCenterRoomBatchData(data.data));
    } catch (error) {
      console.log('Error', error);
      toast.error(messages.error.fetchRoomBatchMapping);
    }
    closeOverlay();
  };

  // Checks whether the batch is already present in the room or not
  const checkBatchRoomMappedOrNot = (
    batchId: string | null,
    batchName: string | null,
    filteredCenterRoomsBatches: MasterSheetAllData[] | null
  ) => {
    // Checking whether the selected batch is mapped or not
    let isRoomMapped: string[] = [];

    filteredCenterRoomsBatches &&
      filteredCenterRoomsBatches[0]?.rooms?.forEach((roomData) => {
        // Iterating through all the rooms for each batch
        roomData?.batches?.forEach((batch) => {
          // Checking whether the batch is already present in the room or not
          if (batch.batchName === batchName && batch.batchId === batchId) {
            isRoomMapped.push(roomData.room);
          }
        });
      });

    return isRoomMapped;
  };

  // Should Run this only when we have the selected batch Data
  const mappedRooms =
    selectedBatchData &&
    checkBatchRoomMappedOrNot(batchId, batchName, filteredCenterRoomsBatches);

  // console.log(
  //   'Checking if the batch is already present in the room:',
  //   mappedRooms
  // );

  return (
    <div className={styles['batch-container']}>
      <LoadingOverlay visible={isOverlayVisible} overlayBlur={2} />
      <div>
        <CenterBatchSelect data={batchSelectData} batch={true} title='Batch' />
      </div>

      <div className={styles['batch-name-id-container']}>
        <label htmlFor='batch-id'>Batch Id</label>
        <input
          id='batch-id'
          type='text'
          readOnly
          value={
            selectedBatch && selectedBatchData
              ? selectedBatchData[0].batchId
              : ''
          }
        />
      </div>

      {selectedBatchData && mappedRooms?.length !== 0 && (
        <div className={styles['mapped-rooms-container']}>
          {mappedRooms?.map((room, index) => (
            <div key={`${room}-${index}`} style={{ color: '#ff4742' }}>
              Mapped to Room - {room}
            </div>
          ))}
        </div>
      )}

      <div>
        {selectedBatchData && mappedRooms?.length === 0 && (
          <button className={styles['save-button']} onClick={handleSave}>
            Save
          </button>
        )}

        {selectedBatchData && mappedRooms?.length !== 0 && (
          <div>Already mapped, delete first and then add</div>
        )}
      </div>
    </div>
  );
};

export default Batch;
