import React from 'react';

import styles from './AddButton.module.css';
import { useDisclosure } from '@mantine/hooks';
import AddModal from '../modal/AddModal';
import { Tooltip } from '@mantine/core';

import Center from '../../modalContent/Center';
import Batch from '../../modalContent/Batch';

import { assets } from '../../../assets/assets';

type Props = {
  title: string;
  room?: string | null;
  center?: string | null;
};

const AddButton: React.FC<Props> = ({ title, room = null, center = null }) => {
  const [opened, { open, close }] = useDisclosure(false);

  const isBatchModal = title.toLowerCase() === 'batch';

  return (
    <div>
      <button className={styles['add-button']} onClick={() => open()}>
        {isBatchModal ? (
          <Tooltip label='Add a Batch' withArrow arrowSize={6} color='green'>
            <img
              src={assets.addIcon}
              alt='add'
              className={styles['add-button-icon']}
            />
          </Tooltip>
        ) : (
          <Tooltip label='Add a Room' withArrow arrowSize={6} color='green'>
            <img
              src={assets.addIcon}
              alt='add'
              className={styles['add-button-icon']}
            />
          </Tooltip>
        )}
      </button>
      {opened && (
        <AddModal title={title} opened={opened} close={close}>
          {isBatchModal ? (
            <Batch close={close} room={room} />
          ) : (
            <Center close={close} />
          )}
        </AddModal>
      )}
    </div>
  );
};

export default AddButton;
