import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Redux
import { store } from './app/store';
import { Provider } from 'react-redux';

// React Router Dom
import { BrowserRouter } from 'react-router-dom';

// @react-oauth/google
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const { REACT_APP_GOOGLE_CLIENT_ID_DEV, REACT_APP_GOOGLE_CLIENT_ID_PROD } =
  process.env;

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={`${REACT_APP_GOOGLE_CLIENT_ID_DEV}`}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
