export const messages = {
  error: {
    // Batch Related
    addBatch: 'Cannot add Room-Batch Mapping',
    deleteBatch: 'Cannot delete Room-Batch Mapping',

    // Fetch Related
    fetchRoomBatchMapping: 'Cannot fetch Room-Batch Mapping Data',
    fetchBatchData: 'Cannot fetch Batch data',
    fetchCentersSheetNameData:
      'Cannot fetch centers and corresponding Sheet Name',
    fetchMasterSheetData: 'Cannot fetch the master sheet data',

    // Room Related
    addRoom: 'Cannot add Room',
  },

  success: {
    // Batch Related
    addBatch: 'Successfully added Room-Batch Mapping',
    deleteBatch: 'Successfully deleted Room-Batch Mapping',

    // Fetch Related
    fetchRoomBatchMapping: 'Successfully fetched Room-Batch Mapping Data',
    fetchBatchData: 'Successfully fetched Batch data',
    fetchCentersSheetNameData:
      'Successfully fetched centers and corresponding Sheet Name',
    fetchMasterSheetData: 'Successfully fetched the master sheet data',

    // Room Related
    addRoom: 'Successfully added Room',
  },
};
