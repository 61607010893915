import React from 'react';

// Mantine
import { Modal } from '@mantine/core';

// Redux
import { useDispatch } from 'react-redux';
import { setSelectedBatch } from '../../../features/batch/batchSlice';

type Props = {
  opened: boolean;
  close: () => void;
  title: string;
  children: React.ReactNode;
};

const AddModal: React.FC<Props> = ({ opened, close, title, children }) => {
  const dispatch = useDispatch();

  const closeBatchModal = () => {
    dispatch(setSelectedBatch(null));
    close();
  };

  return (
    <Modal
      opened={opened}
      onClose={closeBatchModal}
      centered
      withCloseButton={true}
      title={`Add ${title}`}
    >
      {children}
    </Modal>
  );
};

export default AddModal;
