import React from 'react';

// Styles
import styles from './GoogleLoginButton.module.css';

type Props = {
  handleGoogleLogin: () => void;
};

const GoogleLoginButton = ({ handleGoogleLogin }: Props) => {
  return (
    <button
      onClick={() => handleGoogleLogin()}
      className={styles['login-with-google-btn']}
    >
      Sign in with Google
    </button>
  );
};

export default GoogleLoginButton;
