import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  selectedCenter: string | null;
  // Take the [0] to get the object of it
  selectedCenterSheetName: CenterSheetName[] | null;
  centerSelectData: CenterSelect[] | null;
  allCentersSheetName: CenterSheetName[] | null;
};

const initialState: InitialState = {
  selectedCenter: null,
  selectedCenterSheetName: null,
  centerSelectData: null,
  allCentersSheetName: null,
};

const centerSlice = createSlice({
  name: 'centerSlice',
  initialState,
  reducers: {
    setSelectedCenter: (state, action: PayloadAction<string | null>) => {
      // console.log('Selected Center', action.payload);
      state.selectedCenter = action.payload;
    },

    setSelectedCenterSheetName: (
      state,
      action: PayloadAction<CenterSheetName[] | null>
    ) => {
      // console.log('Selected Center Sheet Name', action.payload);
      state.selectedCenterSheetName = action.payload;
    },

    setCenterSelectData: (
      state,
      action: PayloadAction<CenterSelect[] | null>
    ) => {
      // console.log('Center Select Data', action.payload);
      state.centerSelectData = action.payload;
    },

    setAllCentersSheetName: (
      state,
      action: PayloadAction<CenterSheetName[] | null>
    ) => {
      // console.log('All Centers Sheet Name', action.payload);
      state.allCentersSheetName = action.payload;
    },
  },
});

export const {
  setSelectedCenter,
  setSelectedCenterSheetName,
  setCenterSelectData,
  setAllCentersSheetName,
} = centerSlice.actions;

export default centerSlice.reducer;
