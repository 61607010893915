import { useState, useEffect } from 'react';

// React-toastify
import { toast } from 'react-toastify';

// Constants
import { messages } from '../constants';

type Return = {
  batchData: BatchData[] | null;
  rooms: string[] | null;
  batchSelect: BatchSelect[] | null;
};

/**
 * @description - React hook which returns the "Batch Name, Batch ID and the Rooms for the center"
 * @param {string} sheetName - The sheet name for the center
 * @returns {Return} - Returns the "Batch Name, Batch ID and the Rooms for the center" data from the Google Sheets API
 */
const useFetchBatch = (sheetName: string | null): Return => {
  const [batchSelect, setBatchSelect] = useState<BatchSelect[] | null>(null);
  const [batchData, setbatchData] = useState<BatchData[] | null>(null);
  const [rooms, setRooms] = useState<string[] | null>(null);

  const requestUrl = `https://sheets.googleapis.com/v4/spreadsheets/1np61JNaQs6vEWgy3WWRu1-ayxGodHQgBHtnRiWIW1wk/values/${sheetName}!A:C?key=AIzaSyAg5IHogSUlQInimsJNzhtLu9iQspO00U8`;

  useEffect(() => {
    const fetchBatchData = async () => {
      try {
        const response = await fetch(requestUrl, {
          method: 'GET',
        });

        const data = await response.json();

        // Getting the data from the sheet ==> sheetData = [[batchName, batchId, rooms], [batchName, batchId, rooms], ...]
        const sheetData = data.values;

        // Removing the headers from the sheet data
        sheetData?.shift();

        let batchData: BatchData[] = [];
        let roomData: string[] = [];
        let batchSelectData: BatchSelect[] = [];

        // Converting the sheet data to the required format
        sheetData?.forEach((batch: any) => {
          const [batchName = null, batchId = null, room = null] = batch;
          if (batchName && batchId) {
            batchData.push({
              batchName,
              batchId,
            });

            batchSelectData.push({
              label: batchName,
              value: batchName,
            });
          }

          if (room) roomData.push(room);
        });

        // Removing the duplicate values from the batchSelectData array
        const uniqueValues: { [key: string]: boolean } = {};
        const batchSelectDataUniqueValues: BatchSelect[] =
          batchSelectData.filter((entry: BatchSelect) => {
            if (!uniqueValues[entry.value]) {
              uniqueValues[entry.value] = true;
              return true;
            }
            return false;
          });

        // console.log('Batch Data: ', batchData);
        // console.log('Room Data: ', roomData);
        // console.log('Batch Select Data: ', batchSelectData);
        setbatchData(batchData);
        setRooms(roomData);
        setBatchSelect(batchSelectDataUniqueValues);
      } catch (error) {
        toast.error(messages.error.fetchBatchData);
        console.log('Error: ', error);
      }
    };

    fetchBatchData();
  }, [requestUrl]);

  return { batchData, rooms, batchSelect };
};

export default useFetchBatch;
