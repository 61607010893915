import { useEffect } from 'react';

// Componenets
import Header from './header/Header';
import Body from './body/Body';

// Redux
import {
  setCenterSelectData,
  setAllCentersSheetName,
  setSelectedCenterSheetName,
} from '../../../features/center/centerSlice';

import {
  setBatchData,
  setBatchSelectData,
  setRooms,
} from '../../../features/batch/batchSlice';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../../../app/store';

// Custom Hooks
import useFetchCenterSheetName from '../../../hooks/useFetchCenterSheetName';
import useFetchBatch from '../../../hooks/useFetchBatch';

// Styles
import styles from './Tracker.module.css';

type Props = {};

const Tracker = (props: Props) => {
  const dispatch = useDispatch();
  const { selectedCenter, selectedCenterSheetName } = useSelector(
    (state: RootState) => state.centerSlice
  );

  // To get the center select data, and selected center sheet name
  const { centerSelect, centerSheetName } = useFetchCenterSheetName();

  // To fetch the batch data and rooms for the selected center
  const { batchData, rooms, batchSelect } = useFetchBatch(
    selectedCenterSheetName ? selectedCenterSheetName[0].sheetName : null
  );

  // Logic to filter the batch data and rooms based on the selected center
  useEffect(() => {
    // To get the sheet name of the selected center
    const selectedCenterSheetName =
      centerSheetName?.filter((Center) => Center.center === selectedCenter) ??
      null;

    // To get the center data for the "Select" component Mantine
    dispatch(setCenterSelectData(centerSelect));
    // To get the sheet name of all the centers
    dispatch(setAllCentersSheetName(centerSheetName));

    // If we have selected a center, then we will set the selected center sheet name
    selectedCenter
      ? dispatch(setSelectedCenterSheetName(selectedCenterSheetName))
      : dispatch(setSelectedCenterSheetName(null));

    // To set the batch data for the selected center ---> {batchName, batchId}
    dispatch(setBatchData(batchData));
    // To set the data for the "Select" component Mantine
    dispatch(setBatchSelectData(batchSelect));
    // To set the rooms for the selected center
    dispatch(setRooms(rooms));
  }, [
    centerSelect,
    centerSheetName,
    dispatch,
    selectedCenter,
    batchData,
    batchSelect,
    rooms,
  ]);

  return (
    <div className={styles['tracker-container']}>
      <Header />
      <Body />
    </div>
  );
};

export default Tracker;
