import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  data: MasterSheetAllData[] | null;
};

const initialState: InitialState = {
  data: null,
};

const masterSheetSlice = createSlice({
  name: 'masterSlice',
  initialState,
  reducers: {
    setCenterRoomBatchData: (
      state,
      action: PayloadAction<MasterSheetAllData[] | null>
    ) => {
      // console.log('Current Center Room Batches Mapping: ', action.payload);
      state.data = action.payload;
    },
  },
});

export const { setCenterRoomBatchData } = masterSheetSlice.actions;

export default masterSheetSlice.reducer;
