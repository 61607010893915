import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  // For the dropdown that we show on adding the room
  selectedBatch: string | null;
  // Rooms for the selected center, shown when there is no centerData
  rooms: string[] | null;
  // Getting the data for all the batches
  batchData: BatchData[] | null;
  // Data for the selected batch
  selectedBatchData: BatchData[] | null;
  // Data for the select dropdown
  batchSelectData: BatchSelect[] | null;
};

const initialState: InitialState = {
  selectedBatch: null,
  rooms: null,
  batchData: null,
  selectedBatchData: null,
  batchSelectData: null,
};

const batchSlice = createSlice({
  name: 'batchSlice',
  initialState,
  reducers: {
    setSelectedBatch: (state, action: PayloadAction<string | null>) => {
      // console.log('setBatch', action.payload);
      state.selectedBatch = action.payload;
    },

    setRooms: (state, action: PayloadAction<string[] | null>) => {
      // console.log('setRooms', action.payload);
      state.rooms = action.payload;
    },

    setBatchData: (state, action: PayloadAction<BatchData[] | null>) => {
      // console.log('setBatchData', action.payload);
      state.batchData = action.payload;
    },

    setSelectedBatchData: (
      state,
      action: PayloadAction<BatchData[] | null>
    ) => {
      // console.log('setSelectedBatchData', action.payload);
      state.selectedBatchData = action.payload;
    },

    setBatchSelectData: (
      state,
      action: PayloadAction<BatchSelect[] | null>
    ) => {
      // console.log('setBatchSelectData', action.payload);
      state.batchSelectData = action.payload;
    },
  },
});

export const {
  setSelectedBatch,
  setRooms,
  setBatchData,
  setSelectedBatchData,
  setBatchSelectData,
} = batchSlice.actions;

export default batchSlice.reducer;
