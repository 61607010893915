import { useEffect } from 'react';

// Components
import CenterBatchSelect from '../../../common/select/CenterBatchSelect';

// Redux Toolkit
import type { RootState } from '../../../../app/store';
import { useSelector, useDispatch } from 'react-redux';
import { setUserInfo } from '../../../../features/user/userSlice';

// Styles
import styles from './Header.module.css';
import AddButton from '../../../common/buttons/AddButton';

type Props = {};

const Header = (props: Props) => {
  const dispatch = useDispatch();

  const { centerSelectData, selectedCenter } = useSelector(
    (state: RootState) => state.centerSlice
  );

  // To set the user info in the redux store
  useEffect(() => {
    const user = localStorage.getItem('user');

    if (user) {
      const { email, name, image } = JSON.parse(user);
      dispatch(setUserInfo({ name, image, email }));
    }
  }, [dispatch]);

  const { data } = useSelector((state: RootState) => state.userSlice);
  const image = data?.image;
  const name = data?.name;

  // console.log('User data: ', data);

  return (
    <div className={styles['header-container']}>
      <div className={styles['header-add-room-container']}>
        <CenterBatchSelect
          data={centerSelectData}
          batch={false}
          title='Center'
        />
        {selectedCenter && <AddButton title='Room' />}
      </div>
      <div className={styles['user-container']}>
        {image && name && (
          <img className={styles['user-image']} src={image} alt={name} />
        )}
        {name && <div>{name}</div>}
      </div>
    </div>
  );
};

export default Header;
