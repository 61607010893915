// Redux
import { useSelector } from 'react-redux';
import type { RootState } from '../../../../app/store';

// Components
import BatchCard from '../../../cards/BatchCard';

// Styles
import styles from './Body.module.css';

type Props = {};

const Body = (props: Props) => {
  const { data: filteredCenterRoomsBatches } = useSelector(
    (state: RootState) => state.masterSheetSlice
  );

  return (
    <div className={styles['body-container']}>
      Body
      {filteredCenterRoomsBatches &&
        filteredCenterRoomsBatches[0]?.rooms?.map(
          ({ room, batches }, index) => (
            <BatchCard key={`${room}-${index}`} room={room} batches={batches} />
          )
        )}
    </div>
  );
};

export default Body;
