// React
import { useEffect } from 'react';

// Components
import GoogleLoginButton from '../../components/common/buttons/GoogleLoginButton';

// React Router Dom
import { useNavigate } from 'react-router-dom';

// @react-oauth/google
import { useGoogleLogin } from '@react-oauth/google';

// Constants
import { serverUrl } from '../../utils/serverUrl';

// Automatically Removes token after 60 minutes
const LoginPage = () => {
  const navigate = useNavigate();

  // To check whether we already have id_token or not
  useEffect(() => {
    // Storing the JWT Token which we get on google authentication
    if (localStorage.getItem('id_token')) {
      navigate('/');
    }
  }, [navigate]);

  // Google oAuth login using the backend
  const handleGoogleLogin = useGoogleLogin({
    scope:
      'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',

    onSuccess: async ({ code }) => {
      // console.log('Authorization Code after google login: ', code);

      const response = await fetch(`${serverUrl}/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });

      const data = await response.json();

      console.log('All data about the logged in user:', data);

      const { email, name, picture: image } = data.data;

      const user = { email, name, image };

      if (data.success) {
        // Storing the JWT Token which we get on google authentication,
        localStorage.setItem('id_token', data.data.id_token);

        // Storing the refresh token to get new JWT Token after 60 minutes
        localStorage.setItem('refresh_token', data.data.refresh_token);

        // Storing the expiry date of the JWT Token
        localStorage.setItem('expiry_time', data.data.expiry_date);

        localStorage.setItem('user', JSON.stringify(user));

        // Redirecting to the home page
        navigate('/');
      }

      // console.log('Exchanging the token: ', data);
    },
    flow: 'auth-code',
  });

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1.25rem',
      }}
    >
      <div style={{ fontWeight: 'bold' }}>Login using PW account</div>
      <GoogleLoginButton handleGoogleLogin={handleGoogleLogin} />
    </div>
  );
};

export default LoginPage;
