import { useState, useEffect, forwardRef } from 'react';

// Mantine
import type { SelectItemProps } from '@mantine/core';
import { Select, LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../../app/store';
import { setCenterRoomBatchData } from '../../features/master/masterSheetSlice';

// React toastify
import { toast } from 'react-toastify';

// Utils
import { messages } from '../../constants';
import { serverUrl } from '../../utils/serverUrl';

// Styles
import styles from './Center.module.css';

type Props = {
  close: () => void;
};

const Center = ({ close }: Props) => {
  // To get the filtered center data from the sheet
  const { rooms } = useSelector((state: RootState) => state.batchSlice);
  const { data: filteredCenterData } = useSelector(
    (state: RootState) => state.masterSheetSlice
  );
  const { selectedCenter } = useSelector(
    (state: RootState) => state.centerSlice
  );
  const dispatch = useDispatch();

  // Mantine Dev Loader
  const [isOverlayVisible, { open: openOverlay, close: closeOverlay }] =
    useDisclosure(false);

  // State changes
  const [data, setData] = useState<RoomSelect[] | null>(null);
  const [value, setValue] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isRoomPresent, setIsRoomPresent] = useState<boolean>(false);

  // To check whether the selected room has been in the master sheet or not
  useEffect(() => {
    const isRoomPresentFinal = filteredCenterData?.length
      ? filteredCenterData[0]?.rooms.some((row) => row.room === value)
      : false;

    setIsRoomPresent(isRoomPresentFinal);
  }, [filteredCenterData, value]);

  useEffect(() => {
    const roomData: RoomSelect[] = [];
    // Getting the room data of the particular center
    rooms?.forEach((room) => {
      roomData.push({
        value: room,
        label: room,
        group: selectedCenter || '',
      });
    });

    setData(roomData);
  }, [rooms, selectedCenter]);

  // console.log('All Rooms of the center:', data);

  // To handle the change in the selected value of the center and the batch
  const handleChange = (val: string) => {
    // console.log('Value: ', val, 'Type of Value : ', typeof val);
    setValue(val);
  };

  const handleSearchChange = (val: any) => {
    // console.log('Value : ', val, 'Type of Value: ', typeof val);
    setSearchValue(val);
  };

  const handleSave = async (selectedCenter: string | null) => {
    // Data to be added in the master sheet
    const masterSheetRowData = {
      centerName: selectedCenter,
      roomNumber: value,
    };

    openOverlay();

    // To get the name and email from the local storage
    const { name, email } = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user') as string)
      : { name: '', email: '' };

    // Request to add the row in the sheets
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('refresh_token') as string,
        },
        body: JSON.stringify(masterSheetRowData),
      };

      const response = await fetch(
        `${serverUrl}/sheets/add?name=${name}&email=${email}`,
        requestOptions
      );
      const data = await response.json();

      console.log('Data: ', data);
      toast.success(messages.success.addBatch);
    } catch (error) {
      toast.error(messages.error.addBatch);
      console.log('Error: ', error);
    }

    close();

    // Request to fetch the updated data from the sheets
    const requestUrl = `${serverUrl}/sheets/all?selectedCenter=${selectedCenter}`;

    try {
      const response = await fetch(requestUrl);
      const data = await response.json();

      // Setting the updated data of the selected center
      dispatch(setCenterRoomBatchData(data.data));
    } catch (error) {
      console.log('Error', error);
      toast.error(messages.error.fetchRoomBatchMapping);
    }

    closeOverlay();
  };

  const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
    ({ label, ...others }: SelectItemProps, ref) => (
      <div ref={ref} {...others}>
        <div>{label}</div>
      </div>
    )
  );

  return (
    <>
      <Select
        data={data || []}
        itemComponent={SelectItem}
        value={value}
        key={value}
        searchValue={searchValue}
        onSearchChange={handleSearchChange}
        onChange={handleChange}
        dropdownPosition='bottom'
        label={`Select the Room`}
        placeholder={`Select Room`}
        searchable
        nothingFound={`No Room found with the given search query`}
        clearable
        allowDeselect
        filter={(value, item) =>
          item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
          false
        }
        maxDropdownHeight={200}
        miw={385}
        maw={385}
      />
      <LoadingOverlay visible={isOverlayVisible} overlayBlur={2} />
      {isRoomPresent && (
        <div style={{ color: '#ff4742', margin: '0.75rem 0' }}>
          Room Already Present
        </div>
      )}
      {!value && <div style={{ height: '5rem', width: '1rem' }}></div>}
      {!isRoomPresent && value && (
        <div className={styles['add-room-container']}>
          <button
            className={styles['add-room-button']}
            onClick={() => handleSave(selectedCenter)}
          >
            Add Room
          </button>
        </div>
      )}
    </>
  );
};

export default Center;
