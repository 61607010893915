import { useEffect } from 'react';

// Components
import Tracker from '../../components/ui/tracker/Tracker';

// Hooks
import useFetchMasterSheetAllData from '../../hooks/useFetchMasterSheet';

// React Router Dom
import { useNavigate } from 'react-router-dom';

type Props = {};

const TrackerPage = (props: Props) => {
  useFetchMasterSheetAllData();

  const navigate = useNavigate();

  // Logic to check whether we have the valid id token or not and it has expired or not
  useEffect(() => {
    if (!localStorage.getItem('id_token')) {
      navigate('/login');
    } else if (localStorage.getItem('expiry_time')) {
      const expiryTime = parseInt(localStorage.getItem('expiry_time') ?? '0');
      // console.log('Token exipred');
      if (new Date().getTime() > expiryTime) {
        localStorage.clear();
        navigate('/login');
      }
    }
  });

  return (
    <div>
      <Tracker />
    </div>
  );
};

export default TrackerPage;
