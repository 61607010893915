import { useState, useEffect } from 'react';

// React Toastify
import { toast } from 'react-toastify';

// React Redux
import { useDispatch } from 'react-redux';
import { setCenterRoomBatchData } from '../features/master/masterSheetSlice';

// Constants
import { serverUrl } from '../utils/serverUrl';
import { messages } from '../constants';

/**
 * @description - React hook which returns the "Centers and associated GID"
 * @returns - Returns the "Centers" and "GID" data from the Google Sheets API
 */
const useFetchMasterSheetAllData = () => {
  const dispatch = useDispatch();
  const [masterSheetAllData, setMasterSheetAllData] = useState<
    MasterSheetAllData[] | null
  >(null);

  const requestUrl = `${serverUrl}/sheets/all`;

  useEffect(() => {
    /**
     * @desc - Function to fetch the "Centers" and "GID" data from the Google Sheets API
     */
    const fetchMasterSheetAllData = async () => {
      try {
        const response = await fetch(requestUrl, {
          method: 'GET',
        });

        const data = await response.json();

        dispatch(setCenterRoomBatchData(data.data));
        setMasterSheetAllData(data.data);
      } catch (error) {
        toast.error(messages.error.fetchMasterSheetData);
        console.log('Error: ', error);
      }
    };

    fetchMasterSheetAllData();
  }, [dispatch, requestUrl]);

  return { masterSheetAllData };
};

export default useFetchMasterSheetAllData;
