import { forwardRef, useState } from 'react';

// Mantine
import type { SelectItemProps } from '@mantine/core';
import { Select } from '@mantine/core';

// Redux
import { useDispatch } from 'react-redux';
import { setSelectedCenter } from '../../../features/center/centerSlice';
import { setSelectedBatch } from '../../../features/batch/batchSlice';
import { setCenterRoomBatchData } from '../../../features/master/masterSheetSlice';

// React Toastify
import { toast } from 'react-toastify';

// Constants
import { serverUrl } from '../../../utils/serverUrl';
import { messages } from '../../../constants';

type Props = {
  title: string;
  data: CenterSelect[] | BatchSelect[] | null;
  batch?: boolean;
};

const CenterBatchSelect: React.FC<Props> = ({ title, data, batch = false }) => {
  // Redux
  const dispatch = useDispatch();

  // State changes
  const [value, setValue] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleChangeCenter = async (selectedCenter: string) => {
    const requestUrl = `${serverUrl}/sheets/all?selectedCenter=${selectedCenter}`;

    try {
      const response = await fetch(requestUrl);
      const data = await response.json();

      // Giving the data of the selected Center ---> {centerName: string, rooms: {room: string, batches: string[]}[0]
      dispatch(setCenterRoomBatchData(data.data));
    } catch (error) {
      console.log('Error', error);
      toast.error(messages.error.fetchCentersSheetNameData);
    }
  };

  // To handle the change in the selected value of the center and the batch
  const handleChange = (val: string) => {
    // console.log('Value: ', val, 'Type of Value : ', typeof val);

    // If we have the "batch" flag as "true" then we are selecting the batch
    batch ? dispatch(setSelectedBatch(val)) : dispatch(setSelectedCenter(val));

    // If we have the "batch" flag as "false" then we are selecting the center
    !batch && handleChangeCenter(val);
    setValue(val);
  };

  const handleSearchChange = (val: any) => {
    // console.log('Value : ', val, 'Type of Value: ', typeof val);
    setSearchValue(val);
  };

  const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
    ({ label, ...others }: SelectItemProps, ref) => (
      <div ref={ref} {...others}>
        <div>{label}</div>
      </div>
    )
  );

  return (
    <Select
      data={data || []}
      itemComponent={SelectItem}
      value={value}
      key={value}
      searchValue={searchValue}
      onSearchChange={handleSearchChange}
      onChange={handleChange}
      dropdownPosition='bottom'
      label={`Select the ${title}`}
      placeholder={`Select ${title}`}
      searchable
      nothingFound={`No ${title} found with the given search query`}
      clearable
      allowDeselect
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) || false
      }
      maxDropdownHeight={200}
      miw={385}
      maw={385}
    />
  );
};

export default CenterBatchSelect;
