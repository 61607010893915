import { useState, useEffect } from 'react';

// React Toastify
import { toast } from 'react-toastify';

// Constants
import { messages } from '../constants';

/**
 * @description - React hook which returns the "Centers and associated GID"
 * @returns - Returns the "Centers" and "GID" data from the Google Sheets API
 */
const useFetchCenterSheetName = () => {
  const [centerSheetName, setCenterSheetName] = useState<
    CenterSheetName[] | null
  >(null);

  const [centerSelect, setCenterSelect] = useState<CenterSelect[] | null>(null);

  const requestUrl =
    'https://sheets.googleapis.com/v4/spreadsheets/1np61JNaQs6vEWgy3WWRu1-ayxGodHQgBHtnRiWIW1wk/values/centers!A:B?key=AIzaSyAg5IHogSUlQInimsJNzhtLu9iQspO00U8';

  useEffect(() => {
    /**
     * @desc - Function to fetch the "Centers" and "GID" data from the Google Sheets API
     */
    const fetchCenterGid = async () => {
      try {
        const response = await fetch(requestUrl, {
          method: 'GET',
        });

        const data = await response.json();

        // Getting the data from the sheet ==> sheetData = [[center, gid], [center, gid], ...]
        const sheetData = data.values;

        // Removing the headers from the sheet data
        sheetData?.shift();

        let centerSheetNameData: CenterSheetName[] = [];
        let centerSelectData: CenterSelect[] = [];

        // Converting the sheet data to the required format
        sheetData?.forEach((centerGid: any) => {
          const [center, sheetName = null] = centerGid;

          centerSelectData.push({
            label: center,
            value: center,
          });

          centerSheetNameData.push({
            center,
            sheetName,
          });
        });

        // console.log('Center Sheet Name Data: ', centerSheetNameData);
        // console.log('Center Select Data: ', centerSelectData);
        setCenterSheetName(centerSheetNameData);
        setCenterSelect(centerSelectData);
      } catch (error) {
        toast.error(messages.error.fetchCentersSheetNameData);
        console.log('Error: ', error);
      }
    };

    fetchCenterGid();
  }, []);

  return { centerSheetName, centerSelect };
};

export default useFetchCenterSheetName;
