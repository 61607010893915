import { useState } from 'react';

// Mantine Dev
import { Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

// Components
import DeleteModal from '../common/modal/DeleteModal';
import AddButton from '../common/buttons/AddButton';

// Redux
import { useSelector } from 'react-redux';
import type { RootState } from '../../app/store';

// Styles and Assets
import styles from './BatchCard.module.css';
import { assets } from '../../assets/assets';

type Props = {
  room: string | null;
  batches: BatchData[] | null;
};

const BatchCard = ({ room, batches }: Props) => {
  // State Changes
  const [rowData, setRowData] = useState<RowData | null>(null);

  // Mantine Modal Hooks
  const [
    isDeleteModalOpen,
    { open: openDeleteModal, close: closeDeleteModal },
  ] = useDisclosure(false);

  // Redux
  const { selectedCenter } = useSelector(
    (state: RootState) => state.centerSlice
  );

  const handleDeleteBatch = async (
    centerName: string | null,
    roomNumber: string | null,
    batchName: string | null,
    batchId: string | null
  ) => {
    const rowData = {
      centerName,
      roomNumber,
      batchName,
      batchId,
    };

    openDeleteModal();
    setRowData(rowData);
  };

  return (
    <div className={styles['batch-card-container']}>
      {isDeleteModalOpen && (
        <DeleteModal
          isDeleteModalOpen={isDeleteModalOpen}
          openDeleteModal={openDeleteModal}
          closeDeleteModal={closeDeleteModal}
          rowData={rowData}
        />
      )}
      <div>{room}</div>
      <div className={styles['batches']}>
        {batches?.map(
          ({ batchName, batchId }, index) =>
            batchName && (
              <button key={`${index}-${batchName}`} className={styles['batch']}>
                <Tooltip
                  label='Delete the Room Batch Mapping'
                  withArrow
                  arrowSize={6}
                  color='red'
                >
                  <img
                    onClick={() =>
                      handleDeleteBatch(
                        selectedCenter,
                        room,
                        batchName,
                        batchId
                      )
                    }
                    src={assets.removeIcon}
                    alt='Remove the batch'
                    height={20}
                    width={20}
                  />
                </Tooltip>
                {batchName}
              </button>
            )
        )}
        <AddButton title='Batch' room={room} />
      </div>
    </div>
  );
};

export default BatchCard;
