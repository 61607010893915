// Mantine Dev
import { Modal, LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

// React toastify
import { toast } from 'react-toastify';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../../app/store';
import { setCenterRoomBatchData } from '../../../features/master/masterSheetSlice';

// Constants
import { serverUrl } from '../../../utils/serverUrl';
import { messages } from '../../../constants';

// Styles and Assets
import styles from './DeleteModal.module.css';

type Props = {
  isDeleteModalOpen: boolean;
  openDeleteModal: () => void;
  closeDeleteModal: () => void;
  rowData: RowData | null;
};

const DeleteModal = ({
  isDeleteModalOpen,
  openDeleteModal,
  closeDeleteModal,
  rowData,
}: Props) => {
  // Redux
  const dispatch = useDispatch();
  const { selectedCenter } = useSelector(
    (state: RootState) => state.centerSlice
  );

  // Mantine Dev
  const [isLoading, { open: openLoader, close: closeLoader }] = useDisclosure();
  // console.log('rowData from Delete Modal', rowData);

  // To handle the yes button click to delete the data
  const handleDeleteBatch = async () => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('refresh_token') as string,
      },
      body: JSON.stringify(rowData),
    };

    openLoader();

    // To get the name and email from the local storage
    const { name, email } = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user') as string)
      : { name: '', email: '' };

    try {
      let response = await fetch(
        `${serverUrl}/sheets/delete?name=${name}&email=${email}`,
        requestOptions
      );

      const data = await response.json();

      if (data.error) {
        toast.error(messages.error.deleteBatch);
      } else {
        toast.success(messages.success.deleteBatch);
      }
    } catch (error) {
      console.log(error);
      toast.error(messages.error.deleteBatch);
    }

    const requestUrl = `${serverUrl}/sheets/all?selectedCenter=${selectedCenter}`;

    try {
      const response = await fetch(requestUrl);
      const data = await response.json();

      dispatch(setCenterRoomBatchData(data.data));
      // toast.success('Successfully fetch Center Room Batch Mapping');
    } catch (error) {
      console.log('Error', error);
      toast.error(messages.error.fetchRoomBatchMapping);
    }

    // console.log('Delete batch', rowData);

    closeLoader();
    closeDeleteModal();
  };

  const handleDontDeleteBatch = async () => {
    closeDeleteModal();
  };

  return (
    <Modal
      opened={isDeleteModalOpen}
      onClose={closeDeleteModal}
      title={<div className={styles['delete-modal-title']}>Confirm Delete</div>}
      centered
      size='lg'
    >
      {isLoading && <LoadingOverlay visible={isLoading} overlayBlur={2} />}
      <div className={styles['modal-container']}>
        <div>
          Are you sure you want to delete this batch{' '}
          <span className={styles['bold-text']}>{rowData?.batchName}</span> from
          the room{' '}
          <span className={styles['bold-text']}>{rowData?.roomNumber}</span> ?
        </div>
        <div className={styles['button-container']}>
          <button onClick={handleDeleteBatch} className={styles['yes-button']}>
            Yes
          </button>
          <button
            onClick={handleDontDeleteBatch}
            className={styles['no-button']}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
